// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-serial-template-js": () => import("/Users/extent/projects/sites/uns/uns/src/templates/serial-template.js" /* webpackChunkName: "component---src-templates-serial-template-js" */),
  "component---src-pages-404-js": () => import("/Users/extent/projects/sites/uns/uns/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/extent/projects/sites/uns/uns/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/Users/extent/projects/sites/uns/uns/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/Users/extent/projects/sites/uns/uns/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-content-js": () => import("/Users/extent/projects/sites/uns/uns/src/pages/content.js" /* webpackChunkName: "component---src-pages-content-js" */),
  "component---src-pages-content-aa-js": () => import("/Users/extent/projects/sites/uns/uns/src/pages/content/aa.js" /* webpackChunkName: "component---src-pages-content-aa-js" */),
  "component---src-pages-content-permutations-js": () => import("/Users/extent/projects/sites/uns/uns/src/pages/content/permutations.js" /* webpackChunkName: "component---src-pages-content-permutations-js" */),
  "component---src-pages-content-varenlay-js": () => import("/Users/extent/projects/sites/uns/uns/src/pages/content/varenlay.js" /* webpackChunkName: "component---src-pages-content-varenlay-js" */),
  "component---src-pages-index-js": () => import("/Users/extent/projects/sites/uns/uns/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/extent/projects/sites/uns/uns/.cache/data.json")

