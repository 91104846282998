/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

 // You can delete this file if you're not using it

function shufflePermutations() {
    var permcon = document.getElementById("permutations-content");
    if (permcon != null) {
        const scenes = {
            7: "SCENE: AN OLD MAN IS TAKING A WALK.<br>\
            <br>\
            OLD: When I was young, I was always worried about death. I wanted to leave my mark on the world, something permanent.<br>\
            OLD: Yet now, with however few years left in me, I'm somehow... not that scared. Not as concerned.<br>\
            OLD: Maybe I accept it. That I will end. That I've lived enough, and seen enough.<br>\
            OLD: Maybe I'm satisfied. That I /have/ made my mark, and even after I'm gone, I will be carried on.<br>\
            HE PASSES BY AN WALL. THE WORDS 'WE ARE HERE' WERE SPRAY-PAINTED ON IT LONG AGO.\
             TIME HAS WORN IT DOWN; THE PAINT HAS BEGUN TO FADE. THE MAN SMILES.<br>\
            OLD: Or maybe I'm just old and tired, and I don't have the energy anymore to go around trying to live forever.", 

            5: "SCENE: HOSPITAL.<br>       \
            A NOW-MOTHER AND -FATHER ARE IN A HOSPITAL; \
            THE MOTHER IS LYING IN A BED, FATHER SITTING BY HER SIDE. \
            THE MOTHER HOLDS A BABY.<br>\
            <br>\
            MOTHER: You know what's really interesting about entropy?<br>\
            FATHER: Oh, God, here comes another lecture from the future physics professor. I'll just be going--<br>\
            MOTHER: Haha, no. Shut up and sit down.<br>\
            MOTHER: It's really interesting that we can use entropy to follow the arrow of time.<br>\
            FATHER: The universe will always move towards more disorder. <br>\
            MOTHER: When I was younger, my life always seemed to be falling apart. /I/ always felt like I was falling apart.<br>\
            MOTHER: But I learned that that's just how time is. Things fall apart.<br>\
            MOTHER: And as we fall apart, we mix in with the world, too. And it becomes a little bit more like us.<br>\
            MOTHER: We've lost... a lot. Friends and family. And we've... we've been through a lot ourselves. But we /survived/.<br>\
            FATHER: And we carry them on, even after they fell apart.<br>\
            MOTHER: Life is about to get a lot more chaotic for us.<br>\
            FATHER: But that chaos will carry us on, too.<br>\
            MOTHER: Wherever it goes, so will we.<br>\
            <br>\
            FATHER: ...that old wall... I wonder how it's doing?", 

            4: "SCENE: MAN IN CONVERSATION WITH WOMAN.<br>\
            <br>\
            WOMAN: Not you, too.<br>\
            MAN: I'm sorry.<br>\
            WOMAN: Don't apologize. How can you apologize? It's--nothing is your fault.<br>\
            <br>\
            WOMAN: The prognosis... I mean, they've been wrong before. It's not set in stone. <br>\
            MAN: I know. I just wanted to tell you, if... because this might be the last time I can--<br>\
            WOMAN: You're still here! You're still here, and there's still time. We still have time.<br>\
            MAN: ...yeah.<br>\
            WOMAN: We have to believe that things will be okay. I mean... you might not make it, but if we don't believe you can, then you definitely won't.<br>\
            MAN: Yeah. You're right.<br>\
            WOMAN: We'll make it through this together.<br>\
            <br>\
            MAN: I don't want to die.<br>\
            WOMAN: Then don't. For your sake, for mine--for everyone's.<br>\
            MAN: ...yeah. I won't.", 

            6: "SCENE: A BED AT NIGHTTIME. A SMALL BOY WAKES HIS FATHER.<br>\
            <br>\
            CHILD: ...Dad?<br>\
            DAD: Mmmff. Yeah? What is it?<br>\
            CHILD: ...I'm scared.<br>\
            DAD: Scared? Of what?<br>\
            CHILD: I don't want to die.<br>\
            <br>\
            CHILD: ...Dad?<br>\
            DAD: I was afraid, too, when I was a kid. Actually, I still am. <br>\
            CHILD: Really?<br>\
            DAD: Afraid of the future, of everything I can't know. I didn't want to die.\
             I didn't want to grow and change and be a different person, either--because that would be like death, too, in a way. Right?<br>\
            CHILD: Yeah.<br>\
            DAD: It's scary.<br>\
            <br>\
            DAD: You know something that might help both of us?<br>\
            CHILD: What?<br>\
            DAD: Whenever you feel worried about that, that you're going to die or become someone different,\
             that then you'll be gone forever, think to yourself, 'This thought is me. I am still who I was.\
             And through this thought I will be carried on.'<br>\
            DAD: I still think it to myself. And that's how I know that I am still the same person on the\
             inside, so many years later. And if you think it too, then even when I'm gone, I will live on in you,\
             every time you think the same thought. And you can teach your own kid that, too, if you have any!<br>\
            DAD: ...Well, it's not just about the thought. It's also that we are also the people around us.\
             We'll all carry each other on. Together, we can all live forever.<br>\
            <br>\
            CHILD: Okay.<br>\
            DAD: Okay.<br>\
            <br>\
            CHILD: Dad?<br>\
            DAD: Yes?<br>\
            CHILD: I'm still scared. And...<br>\
            DAD: That's okay. Just--<br>\
            CHILD: --And I don't want you to die either.<br>\
            <br>\
            DAD: I'll do my best.", 

            2: "SCENE: CLASSROOM. LECTURER IS IN CONVERSATION WITH HER STUDENT, A GIRL.<br>\
            <br>\
            LECT: You haven't been to class all week.<br>\
            SDNT: I--I know. I'm sorry.<br>\
            LECT: Is everything alright?<br>\
            SDNT: Yeah, I just...<br>\
            <br>\
            SDNT: ...No. Someone really important to me just passed away. And I don't--I don't know what to do.<br>\
            LECT: What do you mean?<br>\
            SDNT: We talked a lot. About everything. And we've been through so much,\
             and we had so much left to do and so much left to say and I can't get it out of my head\
             that he's gone and I'll never--I'll never--<br>\
            LECT: I know how you feel. I...I've lost people too. Recently. And... of course, I might lose more. It's hard.\
             When you lose someone forever, and every little thing becomes a regret, becomes a permanent regret, and\
             everything is a reminder of a light your life used to have.<br>\
            SDNT: I'm sorry. I don't...<br>\
            LECT: Do you remember the lecture from last week? Where I talked about the second law of thermodynamics?<br>\
            SDNT: About entropy?<br>\
            LECT: The amount of disorder in the universe will never decrease over time, which makes natural processes irreversible. <br>\
            SDNT: So... I can't change the past. Thanks. You know, I knew that.<br>\
            LECT: No, it's more like... time only moves in one direction. And it's because things change,\
             and they end -- permanently -- that we can talk about time at all...", 

            3: "SCENE: GUY AT HOME. HE RETRIEVES THE MAIL, THEN NOTICES ONE ENVELOPE OSTENSIBLY FROM A HOSPITAL.<br>\
            <br>\
            THE GUY OPENS IT UP AND READS IT.<br>\
            GUY: Wow, this news is not good.<br>\
            HE TAKES A COUPLE STEPS, THEN STUMBLES ONTO THE COUCH AND COLLAPSES ONTO IT.<br>\
            GUY: Wow, this news is not. Good.<br>\
            HE COVERS HIS EYES WITH THE ARM HOLDING THE LETTER AND GROANS.<br>\
            GUY: I'm sorry. I won't be able to carry you on...<br>\
            <br>\
            GUY: ...But I have people to carry me on, too, right? And everyone that I am, by extension.<br>\
            <br>\
            GUY: This thought is me. I am still who I was. And through this thought I will be carried on.<br>\
            <br>\
            GUY: I don't want to die.<br>\
            GUY: But I suppose no life would ever have been long enough for me.<br>\
            <br>\
            GUY: Dammit, I don't want to die.",

            1: "SCENE: A BLANK WALL. A BOY AND A GIRL STARE AT IT. THEY HOLD CANS OF SPRAY PAINT.<br>\
            <br>\
            BOY: Well, let's get to work.<br>\
            GIRL: Yeah.<br>\
            THEY BEGIN SPRAY PAINTING.<br>\
            GIRL: Before I moved here, back when I still lived with my grandpa, he told me about this wall,\
             same as we're doing now. Except the last time he saw it, it was getting old and cracked.\
              And the paint was fading away.<br>\
            BOY: I guess... I guess nothing lasts forever.<br>\
            GIRL: Yeah.<br>\
            <br>\
            GIRL: But this is the same wall, isn't it? Well, it's a different wall, but it doesn't matter which wall it's on.<br>\
            BOY: Yeah.<br>\
            <br>\
            GIRL: So we can carry on that wall. We'll carry on this wall. And this wall will carry us on.\
             And we'll carry on each other. And... the people we've lost, and the people we'll lose.<br>\
            BOY: Yeah.<br>\
            GIRL: And that's how we'll all last forever.<br>\
            THEY FINISH SPRAY PAINTING AND LEAVE, REVEALING A WALL SPRAY PAINTED WITH 'WE ARE HERE.'"
        };

        var order = [1, 2, 3, 4, 5, 6, 7];

        function shuffleArray(array) {
            for (var i = array.length - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
        };

        shuffleArray(order);

        var story = ""
        for (var i = 0; i < 7; i++) {
            story += scenes[order[i]];
            story += "<br><br><br>";
        }

        permcon.innerHTML = story;
    }
}

exports.onInitialClientRender = () => {
    shufflePermutations();
}